// import router from '@/router'
import {dashboardService, ResponseError} from "@/services/dashboard.service"

const summaryProject = async ({ commit }) => {
    try {
        const result = await dashboardService.getSummary()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const summarySurveyPerforma = async ({ commit }, params) => {
    try {
        const result = await dashboardService.getApiListPerforma(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const eventDateProjectSurvey = async ({ commit }, params) => {
    try {
        const dataParams = {
            month: params.month,
            year: params.year
        }
        const result = await dashboardService.getApiEventDateProjectSurvey(dataParams)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const eventDateSurveyDetail = async ({ commit }, params) => {
    try {
        const result = await dashboardService.getApiEventDateSurveyDetail(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const eventDateProject = async ({ commit }, params) => {
    try {
        const result = await dashboardService.getApiEventDateProject(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}




export default {
    summaryProject,
    eventDateProject,
    eventDateSurveyDetail,
    summarySurveyPerforma,
    eventDateProjectSurvey,
}
const setBillOfInvoice = (state, data) => {
    state.bill_invoice = data
}

const setSubStatus = (state, data) => {
    state.sub_status = data
}

// RESET STATE
const defaultState = (state) => {
    state.responseErrorCode = 0
    state.responseErrorMessage = ''
}
// END RESET STATE

const responsesError = (state, {errorCode, errorMessage}) => {
    state.responseErrorCode = errorCode
    state.responseErrorMessage = errorMessage
}


export default {
    defaultState,
    setSubStatus,
    responsesError,
    setBillOfInvoice
}
<template>
  <svg
    width="22"
    height="28"
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.707 18.793L23 16.086V12.5C22.9969 10.0218 22.075 7.63285 20.4126 5.79498C18.7502 3.95712 16.4654 2.80093 14 2.55V0.5H12V2.55C9.53457 2.80093 7.24976 3.95712 5.58737 5.79498C3.92498 7.63285 3.0031 10.0218 3 12.5V16.086L0.293 18.793C0.105451 18.9805 5.66374e-05 19.2348 0 19.5V22.5C0 22.7652 0.105357 23.0196 0.292893 23.2071C0.48043 23.3946 0.734784 23.5 1 23.5H8V24.5C8 25.8261 8.52678 27.0979 9.46447 28.0355C10.4021 28.9732 11.6739 29.5 13 29.5C14.3261 29.5 15.5979 28.9732 16.5355 28.0355C17.4732 27.0979 18 25.8261 18 24.5V23.5H25C25.2652 23.5 25.5196 23.3946 25.7071 23.2071C25.8946 23.0196 26 22.7652 26 22.5V19.5C25.9999 19.2348 25.8946 18.9805 25.707 18.793ZM16 24.5C16 25.2956 15.6839 26.0587 15.1213 26.6213C14.5587 27.1839 13.7956 27.5 13 27.5C12.2044 27.5 11.4413 27.1839 10.8787 26.6213C10.3161 26.0587 10 25.2956 10 24.5V23.5H16V24.5Z"
      fill="url(#paint0_linear_162_34541)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_162_34541"
        x1="-1.05955e-07"
        y1="14.7364"
        x2="21.7881"
        y2="24.316"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "NotifikasiDashboardIcon",
};
</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b>&copy; PT. Tenaga Kanggo Indonesia (Kanggo), {{ year }}</b
              >.
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="footer-copyright">
              <b>V.1.0.0</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBar",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid #000000;
}
</style>

const responsesError = (state, {errorCode, errorMessage}) => {
    state.responseErrorCode = errorCode
    state.responseError = errorMessage
}

const newProjectSurvey = (state) => {
    state.survey = {
        branch_id: null,
        pic_id: null,
        building_type_id: '',
        date_sesi: '',
        project_description: null,
        address_note: null
    }
}

const setDescription = (state, description) => {
    state.survey.project_description = description
}

const defaultState = (state) => {
    state.responseError = '',
    state.responseErrorCode = 0,
    state.summary = null
}



export default {
    defaultState,
    setDescription,
    responsesError,
    newProjectSurvey,
}
<template>
  <div class="app-wrapper">
    <div class="main-container">
      <NavbarComponent />
      <app-main />
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DataUser } from "../services/storage.service";
import { AppMain, NavbarComponent, FooterComponent } from "./components";

export default {
  name: "TheLayout",
  data() {
    return {
      profileName: null,
      flagFromForgotPassword: false,
    };
  },
  components: {
    NavbarComponent,
    AppMain,
    FooterComponent,
  },
  computed: {
    ...mapGetters("auth", ["isDefaultPassword"]),
  },
  methods: {
    reRender() {
      this.$forceUpdate();
    },
    getDataFromLocal() {
      const storageUser = localStorage.getItem("b2b-alfa");
      const data = JSON.parse(storageUser);
      this.profileName = data ? data.name : null;
    },
    dataFlagLocal() {
      const storage = localStorage.getItem("flag-popup");
      const data = JSON.parse(storage);
      this.flagFromForgotPassword = data === 1 ? true : false;
    },
    getFlagForgotPass() {
      // kalo is_from_forgot_password === 1, munculin modalnya
      if (this.flagFromForgotPassword) {
        const imagePath = require("../assets/img/illustrasi_login_change_password.svg");
        const nameClient = process.env.VUE_APP_NAME_CLIENT;
        this.$swal({
          html: `
            <span>
              <p class="averta-bold is-size-4">Halo, ${this.profileName}!</p>
              <span>
                <small class="averta-reguler">Selamat datang di Dashboard Kanggo x ${nameClient}</small>
              </span>
            </span>
          `,
          imageUrl: imagePath,
          imageWidth: 500,
          imageHeight: 200,
          width: 600,
          height: 400,
          confirmButtonText: "Mulai",
          backdrop: `
            rgba(0, 0, 0, 0.6)
            url("../assets/img/background_login_change_password.svg")
            top
            no-repeat
          `,
          customClass: {
            confirmButton: "swal-button-primary-single",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.reRender();
            DataUser.removeFlag();
          }
        });
      } else {
        this.reRender();
      }
    },
  },
  created() {
    this.dataFlagLocal();
    this.getDataFromLocal();
    this.getFlagForgotPass();
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background: #fafdff;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
</style>

import router from '@/router'
import { userService, AuthenticatorError } from '@/services/user.service'


const login = async ({ commit }, payload) => {
    commit('loginRequest')

    try {
        const token = await userService.login(payload.email, payload.password)
        commit('loginSuccess', {
            accessToken: token.data.data.token,
            isDefaultPass: token.data.data.is_password_default
        })
        router.push(router.history.current.query.redirect || '/')
    } catch (error) {
        if (error instanceof AuthenticatorError) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const changePassword = async ({ commit }, data) => {
    try {
        await userService.changePassword(data)
        commit('ActionSuccess')
    } catch (error) {
        if (error instanceof AuthenticatorError) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const changePasswordFromForgot = async ({ commit }, data) => {
    try {
        await userService.changePasswordFromForgot(data)
        commit('ActionSuccess')
    } catch (error) {
        if (error instanceof AuthenticatorError) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const changePasswordFromProfile = async ({ commit }, data) => {
    try {
        const result = await userService.changePasswordFromProfile(data)
        return result
    } catch (error) {
        if (error instanceof AuthenticatorError) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const logout = ({ commit }) => {
    userService.logout()
    commit('logoutSuccess')
    router.push('/login')
}

const forgotPassword = async ({ commit }, payload) => {
    try {
        await userService.forgotPassword(payload)
        commit('ActionSuccess')
    } catch (error) {
        if (error instanceof AuthenticatorError) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const detailProfile = async ({ commit }) => {
    try {
        const result = await userService.detailProfileApi()
        return result
    } catch (error) {
        if (error instanceof AuthenticatorError) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const updateProfile = async ({ commit }, payload) => {
    try {
        const newObj = {};
        for (const key in payload) {
          if (payload[key] !== null) {
            newObj[key] = payload[key];
          }
        }

        const formData = new FormData()
        formData.append('name', newObj.name)
        if (newObj.image && newObj.image !== null) {
            formData.append('image', newObj.image)
        }
        // formData.append('image', newObj.image)

        const result = await userService.updateProfileApi(formData)
        return result
    } catch (error) {
        if (error instanceof AuthenticatorError) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const removeFlagPassDefault = () => {
    userService.removeFlagPassDefault()
}

export default {
    login,
    logout,
    updateProfile,
    detailProfile,
    forgotPassword,
    changePassword,
    removeFlagPassDefault,
    changePasswordFromForgot,
    changePasswordFromProfile
}
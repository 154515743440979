<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="53.9295"
      cy="53.9312"
      r="41.1976"
      stroke="url(#paint0_linear_743_127353)"
      stroke-width="8.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M82.583 84.7236L98.7348 100.833"
      stroke="url(#paint1_linear_743_127353)"
      stroke-width="8.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_743_127353"
        x1="12.7319"
        y1="53.1822"
        x2="79.1531"
        y2="85.7553"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_743_127353"
        x1="82.583"
        y1="92.6321"
        x2="95.5903"
        y2="99.0275"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SearchIcon",
};
</script>

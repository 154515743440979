<template>
  <b-navbar class="border-navbar is-fixed-top py-4" centered>
    <template #brand>
      <b-navbar-item>
        <img
          :src="logoVendor"
          class="logo-kanggo-alfa"
          alt="kanggoxalfa"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item
        tag="router-link"
        :to="{ path: '/dashboard' }"
        class="mr-3"
        exact
      >
        <DashboardIcon />
        <span class="ml-4 font-highlight">Dashboard</span>
      </b-navbar-item>

      <b-navbar-item
        tag="router-link"
        :to="{ path: '/project' }"
        class="mr-3"
      >
        <ProyekIcon />
        <span class="ml-4 mr-2 font-highlight">Proyek</span>
        <span class="tag is-danger is-rounded" v-if="countProject > 0"
          >{{ countProject }}
        </span>
      </b-navbar-item>

      <!-- <b-navbar-item
        tag="router-link"
        :to="{ path: '/laporan' }"
        class="mr-3"
      >
        <LaporanIcon />
        <span class="ml-4 font-highlight">Laporan</span>
      </b-navbar-item> -->

      <!-- <b-navbar-item
        tag="router-link"
        :to="{ path: '/pengaturan' }"
      >
        <PengaturanIcon />
        <span class="ml-4 font-highlight">Pengaturan</span>
      </b-navbar-item> -->
    </template>

    <template #end>
      <!-- <b-navbar-item href="#">
        <NotifikasiIcon />
        <span class="tag is-danger is-rounded mb-5" v-if="countNotif > 0"
          >{{ countNotif }}
        </span>
      </b-navbar-item> -->
      <span class="is-size-3 mt-2 has-text-weight-light has-text-light">|</span>
      <b-navbar-dropdown
        class="m-2"
        :label="profileName"
        :right="true"
        :collapsible="true"
        :boxed="false"
        :spaced="true"
      >
        <div class="columns">
          <div class="column is-flex is-justify-content-center">
            <figure class="image is-96x96">
              <img
                v-if="imageProfile !== null"
                class="is-rounded image-cover"
                :src="imageProfile"
              />
              <img
                v-else
                class="is-rounded image-cover"
                src="../../assets/img/user-default.svg"
              />
              &nbsp;
              <span class="has-text-centered">
                <p class="font-profile averta-bold is-size-7">
                  ID{{ IdProfile }}
                </p>
                <p class="averta-bold is-size-6">
                  {{ substringName }}
                </p>
                <p>
                  <b-tag class="tag-branch averta-bold" rounded>{{
                    roleProfile
                  }}</b-tag>
                </p>
              </span>
            </figure>
          </div>
        </div>
        <div style="margin-top: 7em;">
          <div class="is-flex is-flex-grow-5 is-flex-direction-column">
            <b-navbar-item tag="router-link" :to="{ path: '/profile' }" class="navbar-item-custom">
              <div class="is-flex is-justify-content-space-between" style="width: 300px;">
                <div class="is-flex">
                  <span class="icon-text">
                    <span class="icon mt-2">
                      <img src="@/assets/img/userIcon.svg"/>
                    </span>
                    <span class="averta-bold is-size-7 is-size-7-mobile mt-2"> Akun Profile </span>
                  </span>
                </div>

                <div class="is-align-items-center is-align-self-flex-end">
                  <span class="icon-text is-align-content-end">
                    <span class="icon averta-bold m-2">
                      <img src="@/assets/img/arrowRight.svg"/>
                    </span>
                  </span>
                </div>
              </div>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/branch-store' }" class="navbar-item-custom">
              <div class="is-flex is-justify-content-space-between" style="width: 300px;">
                <div class="is-flex">
                  <span class="icon-text">
                    <span class="icon mt-2">
                      <img src="@/assets/img/branchsIcon.svg"/>
                    </span>
                    <span class="averta-bold is-size-7 is-size-7-mobile mt-2"> Daftar Cabang </span>
                  </span>
                </div>

                <div class="is-align-items-center is-align-self-flex-end">
                  <span class="icon-text is-align-content-end">
                    <span class="icon averta-bold m-2">
                      <img src="@/assets/img/arrowRight.svg"/>
                    </span>
                  </span>
                </div>
              </div>
            </b-navbar-item>
            <!-- <b-navbar-item href="#" class="navbar-item-custom">
              <div class="is-flex is-justify-content-space-between" style="width: 300px;">
                <div class="is-flex">
                  <span class="icon-text">
                    <span class="icon mt-2">
                      <img src="@/assets/img/S&KIcon.svg"/>
                    </span>
                    <span class="averta-bold is-size-7 is-size-7-mobile mt-2"> Syarat & Ketentuan </span>
                  </span>
                </div>

                <div class="is-align-items-center is-align-self-flex-end">
                  <span class="icon-text is-align-content-end">
                    <span class="icon averta-bold m-2">
                      <img src="@/assets/img/arrowRight.svg"/>
                    </span>
                  </span>
                </div>
              </div>
            </b-navbar-item> -->
            <!-- <b-navbar-item href="#" class="navbar-item-custom">
              <div class="is-flex is-justify-content-space-between" style="width: 300px;">
                <div class="is-flex">
                  <span class="icon-text">
                    <span class="icon mt-2">
                      <img src="@/assets/img/privacyIcon.svg"/>
                    </span>
                    <span class="averta-bold is-size-7 is-size-7-mobile mt-2"> Kebijakan Privasi </span>
                  </span>
                </div>

                <div class="is-align-items-center is-align-self-flex-end">
                  <span class="icon-text is-align-content-end">
                    <span class="icon averta-bold m-2">
                      <img src="@/assets/img/arrowRight.svg"/>
                    </span>
                  </span>
                </div>
              </div>
            </b-navbar-item> -->
            <b-navbar-item @click.prevent="logout()" class="navbar-item-custom">
              <div class="is-flex is-justify-content-space-between" style="width: 300px;">
                <div class="is-flex">
                  <span class="icon-text">
                    <span class="icon mt-2">
                      <img src="@/assets/img/logoutIcon.svg"/>
                    </span>
                    <span class="is-size-7 is-size-7-mobile averta-bold mt-2"> Logout App </span>
                  </span>
                </div>

                <div class="is-align-items-center is-align-self-flex-end">
                  <span class="icon-text is-align-content-end">
                    <span class="icon averta-bold m-2">
                      <img src="@/assets/img/arrowRight.svg"/>
                    </span>
                  </span>
                </div>
              </div>
            </b-navbar-item>
          </div>
        </div>
        <div class="columns is-mobile mt-3">
          <div class="column is-full-fullhd is-three-fifths-mobile is-offset-one-fifth-mobile">
            <div class="content">
              <p class="has-text-centered">
                PT. Tenaga Kanggo Indonesia
                <section class="mt-3">
                  <img src="../../assets/img/facebook-square.svg" alt="" /> &nbsp;
                  <img src="../../assets/img/instagram-square.svg" alt="" /> &nbsp;
                  <img src="../../assets/img/web-square.svg" alt="" /> &nbsp;
                  <img src="../../assets/img/youtube-square.svg" alt="" />
                </section>
              </p>
            </div>
          </div>
        </div>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import {
  DashboardIcon,
  ProyekIcon,
  // LaporanIcon,
  // PengaturanIcon,
  // NotifikasiIcon,
} from "../../components/icons";

export default {
  name: "NavbarComponent",
  components: {
    DashboardIcon,
    // NotifikasiIcon,
    ProyekIcon,
    // LaporanIcon,
    // PengaturanIcon,
  },
  data() {
    return {
      IdProfile: null,
      emailProfile: null,
      profileName: null,
      roleProfile: null,
      imageProfile: null,
      countProject: 0,
      countNotif: 5,
      substringName: '',
      logoVendor: null
    };
  },
  computed: {},
  methods: {
    logout() {
      const imagePath = require("../../assets/img/illustrasi_logout_app.svg");
      this.$swal({
        html: `<p class="averta-bold">Keluar dari Dashboard</p>`,
        backdrop: `
          rgba(0,0,0,0.2)
          left top
          no-repeat
        `,
        imageUrl: imagePath,
        imageWidth: 300,
        imageHeight: 200,
        imageAlt: "Logout App",
        confirmButtonText: "Keluar",
        confirmButtonColor: "#EB4600",
        showCancelButton: true,
        cancelButtonText: "Batalkan",
        reverseButtons: true,
        customClass: {
          confirmButton: "swal-button-primary",
          cancelButton: "swal-button-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("auth/logout");
          this.$store.dispatch("project/resetState");
          this.$store.commit("dashboard/defaultState");
          this.$store.commit("branch/defaultState");
        }
      });
    },
    getProfile() {
      const DATA_USER = 'b2b-alfa'
      this.$store
        .dispatch("auth/detailProfile")
        .then((response) => {
          if (response.data.code === 1) {
            localStorage.setItem(DATA_USER, JSON.stringify(response.data.data));

            this.profileName = response.data.data.name;
            this.IdProfile = response.data.data.id;
            this.emailProfile = response.data.data.email;
            this.roleProfile = response.data.data.role_name;
            this.imageProfile = response.data.data.image_url;
            this.substringName = this.profileName.substring(0, 7)
            this.logoVendor = response.data.data.vendor_logo
          }
        });
    },
  },
  created() {
    this.getProfile();
  },
};
</script>

<style lang="scss" scoped>
.is-size-7 {
  font-size: 0.875rem !important;
}
.logo-kanggo-alfa {
  max-width: 250px;
  max-height: 250px;
}
.border-navbar {
  border-bottom: 1px solid #e1e1e1;
}
.is-active {
  border-bottom: #d9272d 3px solid;
}
.is-active svg,
.is-active .font-highlight {
  fill: #d9272d;
  color: #d9272d;
}
.inactive-link {
  color: gray;
  pointer-events: none;
  cursor: default;
}
.navbar-item img {
  max-height: 7.5rem;
}
.font-profile {
  color: #9a9a9a;
}
.tag-branch {
  background: linear-gradient(90deg, #891313 0%, #d92727 99.97%);
  color: #ffffff;
}
.image-cover {
  object-fit: cover;
  width: 70px;
  height: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.navbar-item-custom {
  border-bottom: 1px solid #e1e1e1;
  gap: 7rem;
}
</style>

import ApiService from './api.service'
import { TokenService, DataUser } from './storage.service'

class AuthenticatorError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.message = message
        this.errorCode = errorCode
    }
}

const userService = {
    login: async function(email, password) {
        const url = process.env.VUE_APP_LOGIN_API
        const requestData = {
            email: email,
            password: password
        }

        try {
            const response = await ApiService.post(url, requestData)
            TokenService.saveToken(response.data.data.token)
            DataUser.saveDataUser(response.data.data)
            ApiService.setHeader()

            if (response.data.data.is_password_default === 1) {
                DataUser.flagConfetti(response.data.data.is_password_default)
            }

            return response
        } catch (error) {
            throw new AuthenticatorError(error.response.status, error.response.data)
        }
    },
    changePassword: async function(payload) {
        try {
            const url = process.env.VUE_APP_CHANGE_PASSWORD_API
            const data = {
                password: payload.password,
                repeat_password: payload.repeat_password
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new AuthenticatorError(error.response.status, error.response.data)
        }
    },
    changePasswordFromForgot: async function(payload) {
        try {
            const url = process.env.VUE_APP_CHANGE_PASSWORD_FORM_FORGOT_API
            const data = {
                password: payload.password,
                repeat_password: payload.repeat_password,
                token: payload.token
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new AuthenticatorError(error.response.status, error.response.data)
        }
    },
    changePasswordFromProfile: async function(payload) {
        try {
            const url = process.env.VUE_APP_CHANGE_PASSWORD_PROFILE_API
            const data = {
                old_password: payload.oldPass,
                password: payload.newPass,
                repeat_password: payload.repeatPass
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new AuthenticatorError(error.response.status, error.response.data)
        }
    },
    logout() {
        TokenService.removeToken()
        TokenService.removeRefreshToken()
        DataUser.removeDataUser()
        ApiService.removeHeader()
    },
    forgotPassword: async function(email) {
        try {
            const url = process.env.VUE_APP_FORGOT_PASSWORD_API
            const payload = {
                email: email
            }
            const response = await ApiService.post(url, payload)
            return response
        } catch (error) {
            throw new AuthenticatorError(error.response.status, error.response.data)
        }
    },
    detailProfileApi: async function() {
        try {
            const url = process.env.VUE_APP_USER_DATA
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new AuthenticatorError(error.response.status, error.response.data)
        }
    },
    updateProfileApi: async function(payload) {
        try {
            const requestData = {
                method: 'POST',
                url: `${process.env.VUE_APP_UPDATE_USER_PROFILE}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new AuthenticatorError(error.response.status, error.response.data)
        }
    }
}

export default userService

export {
    userService,
    AuthenticatorError
}
<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 12.729L5.25 9.729M5.25 9.729L8.25 6.729M5.25 9.729H15.75M12 12.729V13.479C12 14.0757 11.7629 14.648 11.341 15.07C10.919 15.492 10.3467 15.729 9.75 15.729H4.5C3.90326 15.729 3.33097 15.492 2.90901 15.07C2.48705 14.648 2.25 14.0757 2.25 13.479V5.979C2.25 5.38227 2.48705 4.80997 2.90901 4.38801C3.33097 3.96606 3.90326 3.729 4.5 3.729H9.75C10.3467 3.729 10.919 3.96606 11.341 4.38801C11.7629 4.80997 12 5.38227 12 5.979V6.729"
      stroke="#2F2F2F"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "LoginIcon",
};
</script>

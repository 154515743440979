const getIdPic = state => state.pic_id
const getAddress = state => state.address
const getNamePic = state => state.name_pic
const getFileSpk = state => state.file_spk
const getFileSik = state => state.file_sik
const getPhonePic = state => state.phone_pic
const getDateSesi = state => state.date_sesi
const getBranchId = state => state.branch_id
const getPicBranch = state => state.pic_branch
const getNameBranch = state => state.nameBranch
const getAddressAll = state => state.address_all
const getAddressNote = state => state.address_note
const getNoteConfirm = state => state.note_confirm
const getSurveyDetail = state => state.detailsSurvey
const getOfferNumber = state => state.offering_number
const getAddressLatest = state => state.address_latest
const getDateConfirmOffer = state => state.date_confirm_offer
const getDetailProjectMain = state => state.detail_main_project
const getProjectDescription = state => state.project_description
const getDurationPreparation = state => state.duration_preparation




// ERROR RESPONSES
const getResponseErrorCode = state => state.responseErrorCode
const getResponseErrorMessage = state => state.responseErrorMessage


export default {
    getIdPic,
    getFileSpk,
    getFileSik,
    getNamePic,
    getAddress,
    getPhonePic,
    getBranchId,
    getDateSesi,
    getPicBranch,
    getAddressAll,
    getNameBranch,
    getAddressNote,
    getNoteConfirm,
    getOfferNumber,
    getSurveyDetail,
    getAddressLatest,
    getDateConfirmOffer,
    getResponseErrorCode,
    getDetailProjectMain,
    getProjectDescription,
    getDurationPreparation,
    getResponseErrorMessage
}
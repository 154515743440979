<template>
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 4.93009V9.93009H15V4.93009M15 11.5968H16.6667V13.2634H15M7.5 0.763428C7.05797 0.763428 6.63405 0.939022 6.32149 1.25158C6.00893 1.56414 5.83333 1.98807 5.83333 2.43009C5.82749 2.51054 5.82749 2.59131 5.83333 2.67176C3.43333 3.38009 1.66667 5.61343 1.66667 8.26343V13.2634L0 14.9301V15.7634H15V14.9301L13.3333 13.2634V8.26343C13.3333 5.61343 11.5667 3.38009 9.16667 2.67176C9.17251 2.59131 9.17251 2.51054 9.16667 2.43009C9.16667 1.98807 8.99107 1.56414 8.67851 1.25158C8.36595 0.939022 7.94203 0.763428 7.5 0.763428ZM5.83333 16.5968C5.83333 17.0388 6.00893 17.4627 6.32149 17.7753C6.63405 18.0878 7.05797 18.2634 7.5 18.2634C7.94203 18.2634 8.36595 18.0878 8.67851 17.7753C8.99107 17.4627 9.16667 17.0388 9.16667 16.5968H5.83333Z"
      fill="url(#paint0_linear_162_64816)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_162_64816"
        x1="-6.792e-08"
        y1="9.35434"
        x2="13.6821"
        y2="15.7446"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "NotifikasiLoginIcon",
};
</script>

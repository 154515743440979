import Vue from 'vue'
import Router from 'vue-router'
// import { DataUser } from '@/services/storage.service'

Vue.use(Router)

import Layout from '@/layout'
import store from '@/store'

export const constantRoutes = [
    {
        path: '/login',
        name: 'TheLogin',
        component: () => import('@/views/login'),
        hidden: true,
        meta: {
            onlyWhenLoggedOut: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/forgot-password'),
        hidden: true,
        meta: {
            onlyWhenLoggedOut: true
        }
    },
    {
        path: '/forgot-password-profile',
        name: 'ForgotPasswordProfile',
        component: () => import('@/views/forgot-password'),
        hidden: true,
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('@/views/change-password'),
        hidden: true,
        meta: {
            onlyWhenLoggedOut: true
        }
    },
    {
        path: '/password-change',
        name: 'passwordChange',
        component: () => import('@/views/change-password'),
        hidden: true,
    },
    {
        path: '/',
        component: Layout,
        beforeEnter: (to, from, next) => {
            // const storage = localStorage.getItem("b2b-alfa");
            // const data = JSON.parse(storage);
            // if (data.is_password_default === 1) {
            //     next('/password-change')
            // } else {
            //     next()
            // }
            let passDefault = store.getters['auth/isDefaultPassword'];
            if (passDefault === 1) {
                next('/password-change')
            } else {
                next()
            }

        },
        redirect: '/dashboard',
        children: [{
            path: 'dashboard',
            name: 'TheDashboard',
            component: () => import('@/views/dashboard/index'),
            meta: { title: 'Dashboard' }
        }],
    },
    {
        path: '/project',
        component: Layout,
        redirect: '/project',
        name: 'TheProject',
        meta: { title: 'Project Index' },
        children: [
          {
            path: '',
            component: () => import('@/views/project/index'),
            meta: { title: 'Project' }
          },
          {
            path: '/project/survey/create',
            component: () => import('@/views/project/survey-project/create'),
            meta: { title: 'Project Survey Create' }
          },
          {
            path: 'survey/list',
            name: 'TheSurvey',
            component: () => import('@/views/project/survey-project/index'),
            meta: { title: 'Project Survey' }
          },
          {
            path: 'detail/:id',
            name: 'detailProject',
            component: () => import('@/views/project/survey-project/detail'),
            meta: { title: 'Detail Project' }
          },
          {
            path: 'project-main-detail/:id',
            name: 'DetailMainProject',
            component: () => import('@/views/project/DetailMainProject'),
            meta: { title: 'Detail Main Project' }
          }
        ]
    },
    {
        path: '/laporan',
        component: Layout,
        redirect: '/laporan',
        name: 'TheLaporan',
        meta: { title: 'Laporan Index' },
        children: [
            {
                path: '',
                component: () => import('@/views/laporan/index'),
                meta: { title: 'Laporan' }
            }
        ]
    },
    {
        path: '/pengaturan',
        component: Layout,
        redirect: '/pengaturan',
        name: 'ThePengaturan',
        meta: { title: 'Pengaturan Index' },
        children: [
            {
                path: '',
                component: () => import('@/views/pengaturan/index'),
                meta: { title: 'Pengaturan' }
            }
        ]
    },
    {
        path: '/profile',
        component: Layout,
        redirect: '/profile',
        name: 'TheProfile',
        meta: { title: 'Profile' },
        children: [
          {
            path: '',
            component: () => import('@/views/profile/index'),
            meta: { title: 'Profile' }
          },
        ]
    },
    {
        path: '/branch-store',
        component: Layout,
        redirect: '/branch-store',
        name: 'BranchStoreIndex',
        meta: { title: 'Branch Store' },
        children: [
          {
            path: '',
            component: () => import('@/views/branch-store/index'),
            meta: { title: 'Branch Store' }
          },
          {
            path: '/branch-store/detail/:id',
            name: 'detailStore',
            component: () => import('@/views/branch-store/detailStore'),
            meta: { title: 'Detail Branch Store' }
          },
        ]
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/NotFound'),
        hidden: true
    },
]

const createRouter = () => new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
    linkActiveClass: 'is-active'
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router


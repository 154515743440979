// import router from '@/router'
import { projectService, ResponseError } from '@/services/project.service'

const createSurveyProject = async ({ commit }, payload) => {
    try {
        const data = {
            branch_id: payload.branchId,
            pic_id: payload.picBranch,
            building_type_id: !payload.building_type_id ? null : payload.building_type_id,
            date_sesi: payload.dateProject,
            description: !payload.projectDesc ? null : payload.projectDesc,
            address_reference: !payload.addressReference ? null : payload.addressReference,
        }
        const result = await projectService.createProjectSurvey(data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const rescheduleProjectSurvey = async ({ commit }, payload) => {
    try {
        const result = await projectService.rescheduleProjectSurveyApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const cancelRescheduleProjectSurvey = async ({ commit }, params) => {
    try {
        const result = await projectService.cancelRescheduleProjectSurveyApi(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const listRescheduleSurvey = async ({ commit }) => {
    try {
        const result = await projectService.rescheduleListProjectSurveyApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const listProjectSurvey = async ({ commit }, payload) => {
    try {
        const result = await projectService.listProjectSurveyApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const listProjectOffer = async ({ commit }, payload) => {
    try {
        const result = await projectService.listProjectOfferApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const listProjectMain = async ({ commit }, payload) => {
    try {
        const result = await projectService.listProjectMainApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const detailProjectMain = async ({ commit }, payload) => {
    try {
        const result = await projectService.detailProjectMainApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const detailProjectSurvey = async ({ commit }, id) => {
    try {
        const result = await projectService.detailProjectSurveyApi(id)
        commit('setDetailSurvey', result.data.data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const confirmOfferSurvey = async ({ commit }, payload) => {
    try {
        const result = await projectService.confirmOfferApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const offerApprovedRejected = async ({ commit }, payload) => {
    try {
        const result = await projectService.offerApprovedRejectedAPI(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const privateNote = async ({ commit }, payload) => {
    try {
        const result = await projectService.privateNoteSurveyApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const privateNoteMainProject = async ({ commit }, payload) => {
    try {
        const result = await projectService.privateNoteMainProjectApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const cancelProjectSurvey = async ({ commit }, params) => {
    try {
        const result = await projectService.cancelProjectSurveyApi(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const confirmBranchProject = async ({ commit }, payload) => {
    try {
        const result = await projectService.confirmBranchProjectApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

// TABS PROJECT
const getTabsParentProject = async ({ commit }) => {
    try {
        const result = await projectService.tabsParentProject()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsChildProject = async ({ commit }) => {
    try {
        const result = await projectService.tabsChildProject()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsChildOffer = async ({ commit }) => {
    try {
        const result = await projectService.tabsChildOffer()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsInProgressProject = async ({ commit }) => {
    try {
        const result = await projectService.inProgressTabsApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsDoneProject = async ({ commit }) => {
    try {
        const result = await projectService.projectDoneTabsFilterApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsTagihanProject = async ({ commit }) => {
    try {
        const result = await projectService.projectTagihanTabsFilterApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
// END TABS PROJECT

// FOR SET STATE IN MUTATION
const resetState = ({ commit }) => {
    commit('defaultState')
}


export default {
    resetState,
    privateNote,
    listProjectMain,
    listProjectOffer,
    detailProjectMain,
    getTabsChildOffer,
    listProjectSurvey,
    getTabsDoneProject,
    confirmOfferSurvey,
    cancelProjectSurvey,
    detailProjectSurvey,
    createSurveyProject,
    getTabsChildProject,
    getTabsParentProject,
    listRescheduleSurvey,
    confirmBranchProject,
    offerApprovedRejected,
    getTabsTagihanProject,
    privateNoteMainProject,
    rescheduleProjectSurvey,
    getTabsInProgressProject,
    cancelRescheduleProjectSurvey
}
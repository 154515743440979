const loginRequest = (state) => {
    state.authenticating = true;
    state.authenticationError = ''
    state.authenticationErrorCode = 0
}

const loginSuccess = (state, {accessToken, isDefaultPass }) => {
    state.accessToken = accessToken
    state.isDefaultPassword = isDefaultPass
    state.authenticating = false;
}

const loginError = (state, {errorCode, errorMessage}) => {
    state.authenticating = false
    state.authenticationErrorCode = errorCode
    state.authenticationError = errorMessage
}

const ActionSuccess = (state) => {
    state.authenticating = false
    state.authenticationErrorCode = 0
    state.authenticationError = ''
    state.isDefaultPassword = 0
}

const logoutSuccess = (state) => {
    state.accessToken = ''
}

const defaultStateError = (state) => {
    state.authenticating = false
    state.authenticationErrorCode = 0
    state.authenticationError = ''
}

export default {
    loginRequest,
    loginSuccess,
    loginError,
    logoutSuccess,
    ActionSuccess,
    defaultStateError
}
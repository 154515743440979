import axios from 'axios'
import { TokenService, DataUser } from './storage.service'


const ApiService = {
    init(baseUrl) {
        axios.defaults.baseURL = baseUrl
    },
    setHeader() {
        axios.defaults.headers.common["Authorization"] = `${TokenService.getToken()}`
    },
    removeHeader() {
        axios.defaults.headers.common = {}
    },
    get(resource) {
        const api = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API
        })
        api.interceptors.response.use(
            (response) => {
                if (response.status === 200 || response.status === 201) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(response)
                }
            },
            (error) => {
                if (error.response.status === 401) {
                    this.removeHeader
                    DataUser.removeDataUser()
                    TokenService.removeToken()
                    TokenService.removeRefreshToken()

                    // switch (error.response.status) {
                    //     case 401:
                    //         this.removeHeader
                    //         DataUser.removeDataUser()
                    //         TokenService.removeToken()
                    //         TokenService.removeRefreshToken()
                    //         break

                    //     case 403:
                    //         this.$router.replace({
                    //             path: "/login",
                    //             query: {
                    //                 redirect: this.$router.currentRoute.fullPath
                    //             }
                    //         });
                    //         break
                    // }
                }
                return Promise.reject(error)
            }
        )
        return api.get(resource)
    },
    post(resource, data) {
        const api = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API
        })
        api.interceptors.response.use(
            (response) => {
                if (response.status === 200 || response.status === 201) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(response)
                }
            },
            (error) => {
                if (error.response.status === 401) {
                    this.removeHeader
                    DataUser.removeDataUser()
                    TokenService.removeToken()
                    TokenService.removeRefreshToken()

                    // switch (error.response.status) {
                    //     case 401:
                    //         this.removeHeader
                    //         DataUser.removeDataUser()
                    //         TokenService.removeToken()
                    //         TokenService.removeRefreshToken()
                    //         break

                    //     case 403:
                    //         this.$router.replace({
                    //             path: "/login",
                    //             query: {
                    //                 redirect: this.$router.currentRoute.fullPath
                    //             }
                    //         });
                    //         break
                    // }
                }
                return Promise.reject(error);
                // return error.response
            }
        )
        return api.post(resource, data)
    },
    put(resource, data) {
        const api = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API
        })
        api.interceptors.response.use(
            (response) => {
                if (response.status === 200 || response.status === 201) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(response)
                }
            },
            (error) => {
                if (error.response.status === 401) {
                    this.removeHeader
                    DataUser.removeDataUser()
                    TokenService.removeToken()
                    TokenService.removeRefreshToken()

                    // switch (error.response.status) {
                    //     case 401:
                    //         this.removeHeader
                    //         DataUser.removeDataUser()
                    //         TokenService.removeToken()
                    //         TokenService.removeRefreshToken()
                    //         break

                    //     case 403:
                    //         this.$router.replace({
                    //             path: "/login",
                    //             query: {
                    //                 redirect: this.$router.currentRoute.fullPath
                    //             }
                    //         });
                    //         break
                    // }
                }
                return Promise.reject(error)
            }
        )
        return api.put(resource, data)
    },
    delete(resource) {
        const api = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API
        })
        api.interceptors.response.use(
            (response) => {
                if (response.status === 200 || response.status === 201) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(response)
                }
            },
            (error) => {
                if (error.response.status === 401) {
                    this.removeHeader
                    DataUser.removeDataUser()
                    TokenService.removeToken()
                    TokenService.removeRefreshToken()

                    // switch (error.response.status) {
                    //     case 401:
                    //         this.removeHeader
                    //         DataUser.removeDataUser()
                    //         TokenService.removeToken()
                    //         TokenService.removeRefreshToken()
                    //         break

                    //     case 403:
                    //         this.$router.replace({
                    //             path: "/login",
                    //             query: {
                    //                 redirect: this.$router.currentRoute.fullPath
                    //             }
                    //         });
                    //         break
                    // }
                }
                return Promise.reject(error)
            }
        )
        return axios.delete(resource)
    },
    customRequest(data) {
        const api = axios.create({
            baseURL: process.env.VUE_APP_ROOT_API
        })
        api.interceptors.response.use(
            (response) => {
                if (response.status === 200 || response.status === 201) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(response)
                }
            },
            (error) => {
                if (error.response.status === 401) {
                    this.removeHeader
                    DataUser.removeDataUser()
                    TokenService.removeToken()
                    TokenService.removeRefreshToken()

                    // switch (error.response.status) {
                    //     case 401:
                    //         this.removeHeader
                    //         DataUser.removeDataUser()
                    //         TokenService.removeToken()
                    //         TokenService.removeRefreshToken()
                    //         break

                    //     case 403:
                    //         this.$router.replace({
                    //             path: "/login",
                    //             query: {
                    //                 redirect: this.$router.currentRoute.fullPath
                    //             }
                    //         });
                    //         break
                    // }
                }
                return Promise.reject(error)
            }
        )
        return api(data)
    }
}

export default ApiService
const loggedIn = state => state.accessToken ? true : false

const authenticationErrorCode = state => state.authenticationErrorCode

const authenticationError = state => state.authenticationError

const authenticating = state => state.authenticating

const isDefaultPassword = state => state.isDefaultPassword

export default {
    loggedIn,
    authenticationErrorCode,
    authenticationError,
    authenticating,
    isDefaultPassword
}
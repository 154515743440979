import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import store from './store'
import moment from 'moment'
import router from './router'
import GmapVue from 'gmap-vue'
import VCalendar from 'v-calendar'
import VueSweetalert2 from 'vue-sweetalert2'
import ApiService from './services/api.service'
import { TokenService } from './services/storage.service'

// CSS IMPORT
import '@/permission'
import 'buefy/dist/buefy.css'
import '../src/assets/css/main.css'
import '@mdi/font/css/materialdesignicons.css'
import 'sweetalert2/dist/sweetalert2.min.css'


// CSS FRAMEWORK
Vue.use(Buefy, {
  defaultIconPack: 'mdi'
})
Vue.use(VueSweetalert2)
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_KEY_MAPS,
    libraries: 'places'
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.prototype.$moment = moment

// API URL & HEADER
ApiService.init(process.env.VUE_APP_ROOT_API)
if (TokenService.getToken()) {
  ApiService.setHeader()
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

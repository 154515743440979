const setAddress = (state, {address_note, branch_id, address, nameBranch}) => {
    state.address = address
    state.nameBranch = nameBranch
    state.address_note = address_note
    state.branch_id = branch_id
}

const setPicBranch = (state, data) => {
    state.pic_branch = data
}

const setPicId = (state, {idPic, namePic, phonePic}) => {
    state.pic_id = idPic
    state.name_pic = namePic
    state.phone_pic = phonePic
}

const setDescription = (state, description) => {
    state.project_description = description
}

const setDateProject = (state, date) => {
    state.date_sesi = date
}

const setDetailSurvey = (state, data) => {
    state.detailsSurvey = data
}

const setFileSpk = (state, data) => {
    state.file_spk = data
}

const setFileSik = (state, data) => {
    state.file_sik = data
}

const setNoteConfirm = (state, data) => {
    state.note_confirm = data
}

const setDateConfirm = (state, data) => {
    state.date_confirm_offer = data
}

const setOfferNumber = (state, data) => {
    state.offering_number = data
}

const setDetailProjectMain = (state, data) => {
    state.detail_main_project = data
}

const setDurationPreparation = (state, data) => {
    state.duration_preparation = data
}

const setAddressAll = (state, data) => {
    state.address_all = data
}

const setAddressLatest = (state, data) => {
    state.address_latest = data
}


// RESET STATE
const defaultState = (state) => {
    state.branch_id = null
    state.name_pic = null
    state.phone_pic = null
    state.pic_id = null
    state.building_type_id = ''
    state.date_sesi = ''
    state.project_description = null
    state.address_note = null
    state.address = null
    state.nameBranch = null
    state.address_all = null
    state.address_latest = null
    state.pic_branch = null
    state.responseErrorCode = 0
    state.responseErrorMessage = ''
}

const stateDefaultFileSpk = (state) => {
    state.file_spk = null
}

const stateDefaultFileSik = (state) => {
    state.file_sik = null
}

const resetFormConfirmOffer = (state) => {
    state.file_spk = null
    state.file_sik = null
    state.date_confirm_offer = null
    state.note_confirm = null
}

const defaultStateDetail = (state) => {
    state.detailsSurvey = null
    state.offering_number = null
    state.duration_preparation = null
}

const defaultStateDetailMainProject = (state) => {
    state.detail_main_project = null
}

const defaultAddressSelected = (state) => {
    state.address = null
    state.nameBranch = null
    state.address_note = null
    state.branch_id = null
}

const defaultStateRes = (state) => {
    state.responseErrorCode = 0
    state.responseErrorMessage = ''
}
// END RESET STATE

const responsesError = (state, {errorCode, errorMessage}) => {
    state.responseErrorCode = errorCode
    state.responseErrorMessage = errorMessage
}


export default {
    setPicId,
    setFileSpk,
    setFileSik,
    setAddress,
    setPicBranch,
    defaultState,
    setAddressAll,
    setOfferNumber,
    setDateConfirm,
    setDescription,
    responsesError,
    setDateProject,
    setNoteConfirm,
    defaultStateRes,
    setDetailSurvey,
    setAddressLatest,
    defaultStateDetail,
    stateDefaultFileSpk,
    stateDefaultFileSik,
    setDetailProjectMain,
    resetFormConfirmOffer,
    setDurationPreparation,
    defaultAddressSelected,
    defaultStateDetailMainProject
}
<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.68099 0H6.68099C7.13932 0 7.51432 0.375 7.51432 0.833333V5.83333C7.51432 6.29167 7.13932 6.66667 6.68099 6.66667H1.68099C1.22266 6.66667 0.847656 6.29167 0.847656 5.83333V0.833333C0.847656 0.375 1.22266 0 1.68099 0ZM10.0143 0H15.0143C15.4727 0 15.8477 0.375 15.8477 0.833333V5.83333C15.8477 6.29167 15.4727 6.66667 15.0143 6.66667H10.0143C9.55599 6.66667 9.18099 6.29167 9.18099 5.83333V0.833333C9.18099 0.375 9.55599 0 10.0143 0ZM1.68099 8.33333H6.68099C7.13932 8.33333 7.51432 8.70833 7.51432 9.16667V14.1667C7.51432 14.625 7.13932 15 6.68099 15H1.68099C1.22266 15 0.847656 14.625 0.847656 14.1667V9.16667C0.847656 8.70833 1.22266 8.33333 1.68099 8.33333ZM12.5143 8.33333C12.056 8.33333 11.681 8.70833 11.681 9.16667V10.8333H10.0143C9.55599 10.8333 9.18099 11.2083 9.18099 11.6667C9.18099 12.125 9.55599 12.5 10.0143 12.5H11.681V14.1667C11.681 14.625 12.056 15 12.5143 15C12.9727 15 13.3477 14.625 13.3477 14.1667V12.5H15.0143C15.4727 12.5 15.8477 12.125 15.8477 11.6667C15.8477 11.2083 15.4727 10.8333 15.0143 10.8333H13.3477V9.16667C13.3477 8.70833 12.9727 8.33333 12.5143 8.33333Z"
    />
  </svg>
</template>

<script>
export default {
  name: "DashboardIcon",
};
</script>

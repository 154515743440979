<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.575 8.0001C15.575 8.13343 15.554 8.25843 15.512 8.3751C15.4707 8.49176 15.4 8.6001 15.3 8.7001L8.7 15.3001C8.5 15.5001 8.26233 15.6001 7.987 15.6001C7.71233 15.6001 7.475 15.5001 7.275 15.3001C7.075 15.1001 6.975 14.8668 6.975 14.6001C6.975 14.3334 7.075 14.1001 7.275 13.9001L12.175 9.0001L0.975 9.0001C0.691666 9.0001 0.458333 8.9041 0.275 8.7121C0.0916664 8.52076 -3.19822e-07 8.28343 -3.32207e-07 8.0001C-3.44591e-07 7.71676 0.0956665 7.4791 0.287 7.2871C0.479 7.09576 0.716666 7.0001 1 7.0001L12.175 7.0001L7.275 2.1001C7.075 1.9001 6.975 1.66676 6.975 1.4001C6.975 1.13343 7.075 0.900098 7.275 0.700098C7.475 0.500098 7.71233 0.400097 7.987 0.400097C8.26233 0.400097 8.5 0.500098 8.7 0.700098L15.3 7.3001C15.4 7.4001 15.4707 7.50843 15.512 7.6251C15.554 7.74176 15.575 7.86676 15.575 8.0001Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowRightIcon.vue",
};
</script>

const TOKEN_KEY = 'access-token'
const REFRESH_TOKEN_KEY = 'refresh-token'
const DATA_USER = 'b2b-alfa'
const FLAG_POPUP = 'flag-popup'

const TokenService = {
    getToken () {
        return localStorage.getItem(TOKEN_KEY)
    },
    saveToken (accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },
    removeToken () {
        localStorage.removeItem(TOKEN_KEY)
    },
    getRefreshToken () {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },
    saveRefreshToken (refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },
    removeRefreshToken () {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    }
}

const DataUser = {
    saveDataUser (payload) {
        localStorage.setItem(DATA_USER, JSON.stringify(payload))
    },
    flagConfetti (payload) {
        localStorage.setItem(FLAG_POPUP, JSON.stringify(payload))
    },
    removeFlag () {
        localStorage.removeItem(FLAG_POPUP)
    },
    removeDataUser () {
        localStorage.removeItem(DATA_USER)
    },
}

export {
    TokenService,
    DataUser
}
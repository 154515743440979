<template>
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="28" fill="white" />
    <path
      d="M29.8333 29.8333H26.1667V18.8333H29.8333M26.1667 33.5H29.8333V37.1667H26.1667M34.8383 11.5H21.1617L11.5 21.1617V34.8383L21.1617 44.5H34.8383L44.5 34.8383V21.1617L34.8383 11.5Z"
      fill="url(#paint0_linear_360_93254)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_360_93254"
        x1="11.5"
        y1="27.7"
        x2="38.1023"
        y2="40.7458"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#D9272D" />
        <stop offset="0.9999" stop-color="#EB4600" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "ProjectCancelIcon",
};
</script>

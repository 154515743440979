import router from './router/index'
import getPageTitle from '@/utils/get-page-title'
import { TokenService } from '../src/services/storage.service'


const whiteList = [
  '/login',
  '/forgot-password',
  '/change-password'
] // no redirect whitelist

router.beforeEach(async(to, from, next) => {

  document.title = getPageTitle(to.meta.title)

  const hasToken = TokenService.getToken()
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)

  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if (hasToken && onlyWhenLoggedOut) {
        return next('/')
      }
      next()
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next(`/login?redirect=${to.path}`)
    }
  }
})

<template>
  <svg
    width="17"
    height="15"
    viewBox="0 0 17 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5143 15L10.2227 12.5L11.1893 11.5333L12.5143 12.8583L15.506 9.86667L16.4727 11.0417M9.01432 15H2.51432C2.0723 15 1.64837 14.8244 1.33581 14.5118C1.02325 14.1993 0.847656 13.7754 0.847656 13.3333V1.66667C0.847656 0.741667 1.58932 0 2.51432 0H14.181C14.623 0 15.0469 0.175595 15.3595 0.488155C15.6721 0.800716 15.8477 1.22464 15.8477 1.66667V8.16667C15.1143 7.74167 14.256 7.5 13.3477 7.5L12.5143 7.56667V6.66667H4.18099V8.33333H10.5893C9.89982 8.78787 9.33403 9.40656 8.94276 10.1338C8.55149 10.8611 8.347 11.6742 8.34766 12.5C8.34766 13.4083 8.58932 14.2667 9.01432 15ZM8.34766 10H4.18099V11.6667H8.34766M12.5143 3.33333H4.18099V5H12.5143"
    />
  </svg>
</template>

<script>
export default {
  name: "LaporanIcon",
};
</script>

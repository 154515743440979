<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
@font-face {
  font-family: "Averta";
  src: local("Averta"), url("./fonts/Averta-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Averta-Bold";
  src: local("Averta"), url("./fonts/Averta-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Averta-Extra-Bold";
  src: local("Averta"), url("./fonts/Averta-Extra-Bold.otf") format("truetype");
}

@font-face {
  font-family: "Averta-Bold-Italic";
  src: local("Averta"), url("./fonts/Averta-Bold-Italic.otf") format("truetype");
}

@font-face {
  font-family: "Averta-Reguler-Italic";
  src: local("Averta"),
    url("./fonts/Averta-Regular-Italic.otf") format("truetype");
}

@font-face {
  font-family: "Averta-Black";
  src: local("Averta"), url("./fonts/Averta-Black.otf") format("truetype");
}

.b-tabs .tabs .is-active {
  font-family: "Averta-Bold";
}

.b-tabs .tabs .is-active .tag {
  color: white !important;
  background: #d9272d;
}

#app {
  font-family: Averta, Helvetica, Arial, sans-serif;
}

@import url("./assets/css/main.css");
</style>

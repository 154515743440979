import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { TokenService } from '@/services/storage.service'

const state = {
    authenticating: false,
    accessToken: TokenService.getToken(),
    authenticationErrorCode: 0,
    authenticationError: '',
    isDefaultPassword: null
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
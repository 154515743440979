<template>
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.0146484 5.33325C0.0146484 4.87492 0.177982 4.48242 0.504648 4.15575C0.83076 3.82964 1.22298 3.66659 1.68132 3.66659H4.18131V1.99992C4.18131 1.54159 4.34465 1.14936 4.67131 0.823252C4.99743 0.496585 5.38965 0.333252 5.84798 0.333252H10.848C11.3063 0.333252 11.6988 0.496585 12.0255 0.823252C12.3516 1.14936 12.5146 1.54159 12.5146 1.99992V3.66659H15.0146C15.473 3.66659 15.8655 3.82964 16.1921 4.15575C16.5183 4.48242 16.6813 4.87492 16.6813 5.33325V8.66658H13.348V7.83325C13.348 7.59714 13.268 7.39909 13.108 7.23908C12.9485 7.07964 12.7508 6.99992 12.5146 6.99992C12.2785 6.99992 12.0808 7.07964 11.9213 7.23908C11.7613 7.39909 11.6813 7.59714 11.6813 7.83325V8.66658H5.01465V7.83325C5.01465 7.59714 4.93493 7.39909 4.77548 7.23908C4.61548 7.07964 4.41743 6.99992 4.18131 6.99992C3.9452 6.99992 3.74715 7.07964 3.58715 7.23908C3.4277 7.39909 3.34798 7.59714 3.34798 7.83325V8.66658H0.0146484V5.33325ZM5.84798 3.66659H10.848V1.99992H5.84798V3.66659ZM1.68132 13.6666C1.22298 13.6666 0.83076 13.5035 0.504648 13.1774C0.177982 12.8508 0.0146484 12.4583 0.0146484 11.9999V9.49992H3.34798C3.34798 9.73603 3.4277 9.93381 3.58715 10.0933C3.74715 10.2533 3.9452 10.3333 4.18131 10.3333C4.41743 10.3333 4.61548 10.2533 4.77548 10.0933C4.93493 9.93381 5.01465 9.73603 5.01465 9.49992H11.6813C11.6813 9.73603 11.7613 9.93381 11.9213 10.0933C12.0808 10.2533 12.2785 10.3333 12.5146 10.3333C12.7508 10.3333 12.9485 10.2533 13.108 10.0933C13.268 9.93381 13.348 9.73603 13.348 9.49992H16.6813V11.9999C16.6813 12.4583 16.5183 12.8508 16.1921 13.1774C15.8655 13.5035 15.473 13.6666 15.0146 13.6666H1.68132Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ProyekIcon",
};
</script>

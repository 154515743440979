import ApiService from './api.service'

class ResponseError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.message = message
        this.errorCode = errorCode
    }
}

const projectService = {
    listProjectSurveyApi: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_GET_SURVEY}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    listProjectOfferApi: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_GET_LIST_OFFER}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    listProjectMainApi: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_GET_PROJECT}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    detailProjectSurveyApi: async function(id) {
        try {
            const url = `${process.env.VUE_APP_GET_SURVEY}/${id}/detail`
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    createProjectSurvey: async function(payload) {
        try {
            const url = process.env.VUE_APP_CREATE_SURVEY
            const data = {
                branch_id: payload.branch_id,
                pic_id: payload.pic_id,
                building_type_id: payload.building_type_id,
                date_sesi: payload.date_sesi,
                description: payload.description,
                address_reference: payload.address_reference,
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    rescheduleProjectSurveyApi: async function(payload) {
        try {
            const url = process.env.VUE_APP_RESCHEDULE_PROJECT_SURVEY
            const result = await ApiService.post(url, payload)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    rescheduleListProjectSurveyApi: async function() {
        try {
            const url = process.env.VUE_APP_RESCHEDULE_PROJECT_SURVEY
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    cancelRescheduleProjectSurveyApi: async function(id) {
        try {
            const url = `${process.env.VUE_APP_RESCHEDULE_PROJECT_SURVEY}/${id}/cancel`
            const result = await ApiService.put(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    tabsParentProject: async function() {
        try {
            const url = process.env.VUE_APP_TAB_PROYEK_PARENT
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    tabsChildProject: async function() {
        try {
            const url = process.env.VUE_APP_TAB_PROYEK_CHILD_SURVEY
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    tabsChildOffer: async function() {
        try {
            const url = process.env.VUE_APP_TAB_PROYEK_CHILD_QUOTATION
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    confirmOfferApi: async function(payload) {
        try {
            const requestData = {
                method: 'POST',
                url: `${process.env.VUE_APP_OFFER_UPLOAD_SPK}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    inProgressTabsApi: async function() {
        try {
            const url = process.env.VUE_APP_TAB_PROYEK_CHILD_PROJECT_ACTIVE
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    projectDoneTabsFilterApi: async function() {
        try {
            const url = process.env.VUE_APP_TAB_PROYEK_CHILD_PROJECT_COMPLETE
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    offerApprovedRejectedAPI: async function(payload) {
        try {
            const url = process.env.VUE_APP_OFFER_APPROVE_REJECT
            const result = await ApiService.post(url, payload)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    projectTagihanTabsFilterApi: async function() {
        try {
            const url = process.env.VUE_APP_TAB_PROYEK_CHILD_PROJECT_UNPAID
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    detailProjectMainApi: async function(params) {
        try {
            const url = `${process.env.VUE_APP_GET_PROJECT}/${params}/detail`
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    privateNoteSurveyApi: async function(payload) {
        try {
            const url = `${process.env.VUE_APP_CREATE_SURVEY}/personal-note`
            const data = {
                survey_id: payload.id,
                note: payload.private_note,
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    privateNoteMainProjectApi: async function(payload) {
        try {
            const url = `${process.env.VUE_APP_GET_PROJECT}/personal-note`
            const data = {
                project_id: payload.id,
                note: payload.private_note,
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    cancelProjectSurveyApi: async function(id) {
        try {
            const requestData = {
                method: 'PUT',
                url: `${process.env.VUE_APP_CREATE_SURVEY}/${id}/cancel`,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    confirmBranchProjectApi: async function(payload) {
        try {
            const url = `${process.env.VUE_APP_GET_PROJECT}/confirm`
            const data = {
                homecare_project_id: payload
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },

}

export default projectService

export {
    projectService,
    ResponseError
}
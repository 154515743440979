import ApiService from './api.service'

class ResponseError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.message = message
        this.errorCode = errorCode
    }
}

const branchService = {
    getBranch: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_BRANCHES}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    picBranchApi: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_PIC_BRANCH}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    registerPicBranch: async function(payload) {
        try {
            const url = process.env.VUE_APP_REGISTER_PIC_BRANCH
            const data = {
                role_id: process.env.VUE_APP_ROLE_ID,
                phone: payload.phone,
                name: payload.name,
                email: payload.email,
                branch_ids: payload.branch_ids
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    summaryBranchPicApi: async function() {
        try {
            const url = process.env.VUE_APP_SUMMARY_USER_BRANCH_STORE
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    storeListBranchApi: async function() {
        try {
            const url = process.env.VUE_APP_STORE_LIST_BRANCH
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    deletePicBranchApi: async function(path) {
        try {
            const url = `${process.env.VUE_APP_DELETE_PIC_BRANCH}/${path}`
            const result = await ApiService.delete(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    }
}

export default branchService

export {
    branchService,
    ResponseError
}